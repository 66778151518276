<script lang="ts" setup>
const props = defineProps<{
  title: string
  index: number
}>()

const activeIndex: Ref<number> = inject('activeIndex')!
const isActive = computed(() => activeIndex.value === props.index)

const open = (index: number) => {
  activeIndex.value = activeIndex.value !== index ? index : -1
}

const content = ref(null)
const { height: contentHeight } = useElementSize(content)
</script>

<template>
  <li
    :class="['accordion-item', { 'is-active': isActive }]"
    :is-active="isActive"
    :style="{ ['--height-inner-content']: `${contentHeight}px` }"
  >
    <div class="accordion-head" role="button" @click="() => open(index)">
      <h4 class="title">{{ title }}</h4>
      <ButtonBase>
        <slot name="icon" :is-active="isActive">
          <Icon :name="isActive ? 'mini-minus-small' : 'mini-plus-small'" />
        </slot>
      </ButtonBase>
    </div>

    <div class="accordion-content">
      <div ref="content" class="content-wrapper">
        <div class="inner-content">
          <slot />
        </div>
      </div>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.accordion-item {
  --c-head: var(--c-primary);
  margin-bottom: 24px;
  border-bottom: 1px solid var(--c-line-light);

  @include hover {
    --c-head: var(--c-blue-rollover);
    .button {
      --color-border: var(--c-head);
      :deep(.background) {
        border-width: 2px;
        transform: scale(0.92);
      }
    }
  }
  &.is-active {
    --c-head: var(--c-blue-rollover);
    .accordion-content {
      height: var(--height-inner-content);
    }
  }
}
.accordion-head {
  @include fluid(padding-block, 12px, 16px);

  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: center;

  cursor: pointer;
}

.title {
  color: var(--c-head);
  @include transitions(color);
  margin-bottom: 0;
}

.accordion-content {
  position: relative;
  height: 0;
  overflow-x: hidden;

  @include transitions(height);
}

.inner-content {
  padding-block: 10px 45px;
}

.button {
  @include fluid(--p, 13px, 18px);
  --pv: var(--p);
  --ph: var(--p);
  --color: var(--c-head);
  --color-border: var(--c-blue-30);
}
</style>
